import React, {useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import BannerEmergencias from './Banner/images/Emergencias';
//import BannerShadow from './Banner/images/Shadow';

const BannerSlider = () => {
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const [ index, setIndex ] = useState<number>(0);

  const handleChangeIndex = ( index:number ) => {
    setIndex( index );
  };

  return (
    <div className="banner-slider" key={index}>
      <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        <BannerEmergencias  className="item-slider item-emergencies" />
      </AutoPlaySwipeableViews>
    </div>
  )
}

export default BannerSlider;
/*
import React, {useState} from 'react';

const BannerSlider = () => {
  

  return (
    <div className="banner-slider">
      hola!npm
    </div>
  )
}

export default BannerSlider;
*/