import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import RightArrow from '../../assets/icons/right_arrow.svg';
import RightDown from '../../assets/icons/right_down.svg';

import { UseWsp, BatteryHome, Installing } from '../../images/HowWorksImages';

const HowWorks = () => {
  return(
    <div className="how-works">
      <Container>
        <h3>
          Cómo funciona el servicio
        </h3>
        <Container maxWidth="md">
          <p>
            Recuerda que solo tienes que pagar el costo de la batería, el servicio incluye la instalación <strong>gratis en domicilio</strong> junto con la revisión del sistema de carga de alternador de tu vehículo.
          </p>
        </Container>

        <Grid container className="how-works-steps" spacing={3} alignItems="center">
          <Grid item xs={12} sm className="how-works-steps-item">
            <div className="how-works-steps-img">
              <UseWsp />
            </div>
            <h5>Consulta por tu batería vía<br />teléfono, whatsapp o email</h5>
          </Grid>
          <Grid item xs={12} sm="auto" className="how-works-steps-item">
           <Hidden xsDown>
              <RightArrow />
            </Hidden>
            <Hidden smUp>
              <RightDown />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm className="how-works-steps-item">
            <div className="how-works-steps-img">
              <BatteryHome />
            </div>
            <h5>Llevamos tu batería<br />donde estés</h5>
          </Grid>
          <Grid item xs={12} sm="auto" className="how-works-steps-item">
            <Hidden xsDown>
              <RightArrow />
            </Hidden>
            <Hidden smUp>
              <RightDown />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm className="how-works-steps-item">
            <div className="how-works-steps-img">
              <Installing />
            </div>
            <h5>Instalamos la batería y<br />revisamos el sistema eléctrico</h5>
          </Grid>
        </Grid>

      </Container>
    </div>
  )
}

export default HowWorks;