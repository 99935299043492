import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Container from '@material-ui/core/Container';
import BackgroundImage from 'gatsby-background-image'
import BigButton from '../../../common/BigButton';
import Grid from '@material-ui/core/Grid';
import WspWhite from '../../../../assets/icons/whatsapp_white.svg';
import PhoneWhite from '../../../../assets/icons/phone_white.svg';

const handleWsp = () => {
  window.open('https://wa.me/56976433061?text=Hola!%20necesito%20ayuda%20con%20mi%20batería', '_blank');
}

const handleCall = () => {
  window.location.href = `tel://+56976433061`;
}

const BackgroundImageSrc = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "images/banner/bateriaexpress_home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        >
          <Container>
            <div className="item-emergencies-title">
              ¿Problemas con tu batería?
            </div>
            <div className="item-emergencies-teaser">
              Llegamos donde te encuentres en cualquier lugar de Santiago.
            </div>
            <div className="item-emergencies-actions">
              <Grid container spacing={2}>
                <Grid item xs={12} sm="auto">
                  <BigButton label="(+56 9) 7 643 30 61" sublabel="Escríbenos al" type="wsp-button" icon={ <WspWhite /> } action={handleWsp} />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <BigButton label="(+56 9) 7 643 30 61" sublabel="Llámanos al" type="call-button" icon={ <PhoneWhite /> } action={handleCall} />
                </Grid>
              </Grid>
            </div>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

const BannerEmergencias = styled(BackgroundImageSrc)`
  background-position: center;  
`

//background-repeat: repeat-y;
//background-size: cover;

export default BannerEmergencias