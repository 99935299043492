import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FastDelivery from '../../assets/icons/fast_delivery.svg';
import Open247 from '../../assets/icons/open247.svg';
//import Clock from '../../assets/icons/clock.svg';
import Payments from '../../assets/icons/payments.svg';

const TopBar = () => {
  return (
    <div className="top-header">
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center" className="top-header-content">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <FastDelivery />
              </Grid>
              <Grid item className="subsmall">
                Instalación a domicilio ¡GRATIS!
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Open247 />
              </Grid>
              <Grid item className="subsmall">
                Atención 24/7 en todo Santiago
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Payments />
              </Grid>
              <Grid item className="subsmall">
                Elige tu medio de pago: efectivo, transferencia y tarjeta
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </div>
  )
}

export default TopBar;