import React from 'react';
import '../styles/main.scss'

import TopBar from '../components/common/TopBar';
import Header from '../components/common/Header';
import BannerSlider from '../components/home/BannerSlider';
import ServiceTeaser from '../components/common/ServiceTeaser';
import BatteryBrands from '../components/common/BatteryBrands';
import HowWorks from '../components/common/HowWorks';
import BannerBateriaExpress from '../components/common/BannerBateriaExpress';
import Contacto from '../components/common/Contacto';
import SEO from '../components/seo';


export default () => {
  return (
    <main>
      <TopBar />
      <Header />
      <SEO title="Baterías para auto a domicilio 24/7 en todo Santiago" />
      <BannerSlider />
      <ServiceTeaser />
      <BatteryBrands />
      <HowWorks />
      <BannerBateriaExpress />
      <Contacto />
      
    </main>
  )
}
