import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Bolt from '../../assets/icons/bolt.svg';
import WspWhite from '../../assets/icons/whatsapp_white.svg';
import PhoneRed from '../../assets/icons/phone_red.svg';
import BigButton from '../common/BigButton';
import Hidden from '@material-ui/core/Hidden';

const handleWsp = () => {
  window.open('https://wa.me/56976433061?text=Hola!%20necesito%20ayuda%20con%20mi%20batería', '_blank');
}

const handleCall = () => {
  window.location.href = `tel://+56976433061`;
}

const BannerBateriaExpress = () => {
  return (
    <div className="banner-bateriaexpress">
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} sm className="banner_bateriaexpress-text">
            <h5>Encuentra la batería para tu auto o camioneta en bateriaexpress.cl</h5>
          </Grid>
          <Grid item xs={12} sm={8} className="banner_bateriaexpress-actions">
            <Hidden xsDown>
              <Bolt className="banner_bateriaexpress-icon" />
            </Hidden>
            <Grid container spacing={3}>
              <Grid item md={2}></Grid>
              <Grid item xs={12} md={5}>
                <BigButton label="(+56 9) 7 643 30 61" sublabel="Escríbenos al" type="wsp-button" icon={ <WspWhite /> } action={handleWsp} />
              </Grid>
              <Grid item xs={12} md={5}>
                <BigButton label="(+56 9) 7 643 30 61" sublabel="Llámanos al" type="call-button-white" icon={ <PhoneRed /> } action={handleCall} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default BannerBateriaExpress;