import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import MapIcon from '../../assets/icons/map.svg';
import MailIcon from '../../assets/icons/mail.svg';
import TbkImg from '../../images/TbkImg';

const ServiceTeaser = () => {
  return (
    <div className="service-teaser">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm>
            <div className="service-teaser-item">
              <div className="service-teaser-image">
                <MapIcon />
              </div>
              <h5>Atendemos a domicilio </h5>
              <p className="semismall">
                Nuestro servicio cubre todo Santiago, en el momento que necesites y donde estés
                <br /><span>¡SIN COSTOS ADICIONALES!</span>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Hidden xsDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm>
            <div className="service-teaser-item">
              <div className="service-teaser-image">
                <MailIcon />
              </div>
              <h5>¿Tienes más preguntas?</h5>
              <p className="semismall">
                Puedes escribirnos a <strong><a href="mailto:ventas@bateriaexpress.cl">ventas@bateriaexpress.cl</a></strong> o visitarnos en nuestro taller (<a href="#">Ver ubicación</a>).
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Hidden xsDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm>
            <div className="service-teaser-item">
              <div className="service-teaser-image">
                <TbkImg />
              </div>
              <h5>Aceptamos diversos medios de pago</h5>
              <p className="semismall">
                Puedes pagar en efectivo, vía transferencia o utilizar tus tarjetas de débito y crédito.
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ServiceTeaser;