import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import BigButton from './BigButton';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import LogoWhite from '../../assets/images/logo_white.svg';
import TbkImg from '../../images/TbkImg';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Contacto = () => {
  const [ mailSent, setMailSent ]       = useState<boolean>( false );
  const [ mailError, setMailError ]     = useState<boolean>( false );
  const [ loading, setLoading ]         = useState<boolean>( false );
  const [ name, setName ]               = useState<string>('');
  const [ mail, setMail ]               = useState<string>('');
  const [ model, setModel ]             = useState<string>('');
  const [ msg, setMsg ]                 = useState<string>('');

  const [ validName, setValidName ]     = useState<string>('');
  const [ validMail, setValidMail ]     = useState<string>('');
  const [ validModel, setValidModel ]   = useState<string>('');
  const [ validMsg, setValidMsg ]       = useState<string>('');

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://static.zdassets.com/ekr/snippet.js?key=9eb9074a-b554-42ee-9e06-ce386604196e";
    script.id = "ze-snippet";
    
    script.async = true;
    document.body.appendChild(script);
  });

  const handleMail = ( email:string ) => {
    setMail( email );

    if( email === '' ){
      setValidMail( '' );
    } else {
      // don't remember from where i copied this code, but this works.
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if ( re.test(email) ) {
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
          setValidMail( '' );
      }
      else {
          // invalid email, maybe show an error to the user.
          setValidMail( 'Debes ingresar un correo válido.' );
      }
    }
  }

  const handleSubmit = (event:any|void) => {
    let errorCount = 0;
    event.preventDefault();
    setLoading( true );

    // Realizamos las validaciones
    if( name === '' ){
      errorCount += 1;
      setValidName( 'El nombre es un campo obligatorio' );
    }

    if( mail === '' ){
      errorCount += 1;
      setValidMail( 'El correo es un campo obligatorio' );
    }

    if( model === '' ){
      errorCount += 1;
      setValidModel( 'El modelo es un campo obligatorio' );
    }

    if( msg === '' ){
      errorCount += 1;
      setValidMsg( 'El mensaje es un campo obligatorio' );
    }

    if( errorCount === 0 ){

      axios.post('http://api.bateriaexpress.cl/api/post/form/contact', {
        name: name,
        mail: mail,
        model: model,
        msg: msg,
      }).then((res) => {
        setLoading( false );
        setMailSent( true );
        setName('');
        setMail('');
        setModel('');
        setMsg('');

      }).catch((error) => {
        setLoading( false );
        setMailError( true );
      });
    } else {
      setLoading( false );
    }
  }
  
  return (
    <div className="contact-block">
      <Container>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={4} >
            <div className="contact-block-form">

              <h4>
                Encuéntranos en nuestro local:
                <br/>Copiapó 322, Santiago, Chile.
              </h4>
              <p>
                Si deseas comunicarte con nosotros, puedes completar el siguiente formulario y nos comunicaremos contigo a la brevedad.
              </p>

              <form method="post" onSubmit={handleSubmit} autoComplete="off">
                <FormControl fullWidth={true}>
                  <TextField
                    label="Nombre"
                    value={name}
                    required={true}
                    variant="filled"
                    color="secondary"
                    onChange={(event:any) => { setMailError(false); setMailSent(false); setValidName(''); setName(event.target.value)  } }
                    helperText={ validName === '' ? '' : validName }
                    error={ validName !== '' ? true : false }
                    />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    label="Email"
                    value={mail}
                    required={true}
                    variant="filled"
                    color="secondary"
                    onChange={(event:any) => { setMailError(false); setMailSent(false); handleMail( event.target.value );  } }
                    helperText={ validMail === '' ? '' : validMail }
                    error={ validMail !== '' ? true : false }  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    label="Modelo de vehículo"
                    value={model}
                    required={true}
                    variant="filled" 
                    color="secondary"
                    onChange={(event:any) => { setMailError(false); setMailSent(false); setValidModel(''); setModel(event.target.value)  } }
                    helperText={ validModel === '' ? '' : validModel }
                    error={ validModel !== '' ? true : false } />
                </FormControl>

                <FormControl fullWidth={true}>
                  <TextField
                    label="Mensaje"
                    value={msg}
                    required={true}
                    variant="filled"
                    color="secondary"
                    multiline
                    rows={3}
                    onChange={(event:any) => { setMailError(false); setMailSent(false); setValidMsg(''); setMsg(event.target.value)  } }
                    helperText={ validMsg === '' ? '' : validMsg }
                    error={ validMsg !== '' ? true : false } />
                </FormControl>

                
                { mailError &&
                    <React.Fragment>
                      
                      <Typography variant="body1" color="secondary" align="center">
                        <ReportProblemOutlinedIcon />
                        <br />
                        Lo sentimos, hubo un problema al enviar el formulario, por favor intenta comunicarte con nosotros por teléfono o whatsapp.
                      </Typography>
                      <br />
                      <br />
                    </React.Fragment>
                }
                { mailSent &&
                    <React.Fragment>
                      <Typography variant="body1" align="center">
                        Hemos recibido tu mensaje, pronto nos contactaremos contigo.
                      </Typography>
                      <br />
                      <br />
                    </React.Fragment>
                }
                { loading ?
                      <CircularProgress color="secondary" />
                    :
                      <BigButton label="Enviar" type="button-red" action={handleSubmit} />
                }

                <div className="g-recaptcha" data-sitekey="6LfIlvQUAAAAAN_473uXuWeveSOha_WL93ax-IfU"></div>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="contact-block-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.8641044028263!2d-70.63669568425826!3d-33.45284688077313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c570136b839f%3A0x12c065a73451fc75!2sCopiap%C3%B3%20322%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1589297930573!5m2!1ses!2scl" width="100%" height="500" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
      <Container className="footer">
          <Grid container className="footer-content" spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <LogoWhite />
              <br />
              <Typography variant="caption">
                Servicio de delivery e instalación de baterías a domicilio
              </Typography>
              <br />
              <TbkImg />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom>
                ¡Contáctanos!
              </Typography>
              <ul>
                <li key="li_contact_0">
                  <Typography variant="subtitle2">
                    <WhatsAppIcon /> <PhoneIcon /> <a href="https://wa.me/56976433061?text=Hola!%20necesito%20información%20sobre%20sus%20baterías" target="_blank" className="red-footer">+56 9 7 643 30 61</a>
                  </Typography>
                </li>
                <li key="li_contact_1">
                  <Typography variant="subtitle2">
                    <MailIcon /> <a href="mailto:ventas@bateriaexpress.cl" target="_blank" className="red-footer">ventas@bateriaexpress.cl</a>
                  </Typography>
                </li>
                <li key="li_contact_2">
                  <Typography variant="subtitle2">
                    <HomeIcon /> Copiapó 322, Santiago, Chile.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
    </div>
  )
}

export default Contacto;