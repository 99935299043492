import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

interface buttonProps {
  icon     ?: any,
  type     ?: string,
  label     : string,
  sublabel ?: string,
  action()  : void,
}

const BigButton = ( props:buttonProps ) => {

  return (
    <Button className={ "bigbutton " + ( props.type ? props.type : '' ) } color="inherit" onClick={props.action} >
      <Grid container alignItems="center" spacing={2}>
        { props.icon &&
            <Grid item className="bigbutton-icon">
              { props.icon }
            </Grid>
        }
        <Grid item className="bigbutton-text">
          { props.sublabel &&
            <React.Fragment>
              <span className="sublabel">{props.sublabel}</span>
            </React.Fragment>
          }
          <div className="label">{ props.label }</div>
        </Grid>
      </Grid>
    </Button>
  )
}

export default BigButton;