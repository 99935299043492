import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { StaticImage } from "gatsby-plugin-image"

const BatteryBrands = () => {
  return(
    <div className="battery-brands">
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={4}>
            <div className="battery-brands-text">
              <h5>
                Trabajamos con <strong>baterías de marcas reconocidas</strong> que entregan calidad, tecnología y seguridad
              </h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} className="battery-brands-image">
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} className="battery-brands-image-item">
                <StaticImage src="../../assets/images/logos/bosch.png" alt="Baterías BOSCH" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} className="battery-brands-image-item">
                <StaticImage src="../../assets/images/logos/yuasa.png" alt="Baterías Yuasa" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} className="battery-brands-image-item">
                <StaticImage src="../../assets/images/logos/hankook.png" alt="Baterías Hankook" />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm={4} md={3} className="battery-brands-image-item">
                  <StaticImage src="../../assets/images/logos/gentry.jpg" alt="Baterías Gentry" />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default BatteryBrands;