import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TbkImg = data => (

  <StaticQuery
    query={graphql`
      query {
        tbkImg: file(relativePath: { eq: "images/transbank_v2.png" }) {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    //render={ data => <Img fluid={data.tbkImg.childImageSharp.fluid} /> }
    render={ data => <img src={data.tbkImg.childImageSharp.fluid.src} style={{ maxWidth: '100%', }} /> }
  />
)

export default TbkImg