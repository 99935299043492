import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/images/logo_color.svg';
import WspIcon from '../../assets/icons/whatsapp.svg';
import { Link } from "gatsby"

const Header = () => {
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Container className="header-container">
          <Logo />
          <Hidden smDown>
            <div className="header-subtitle semismall">
              Servicio de delivery e instalación de baterías a domicilio
            </div>
          </Hidden>
          <Hidden xsDown>
            <div className="header-whatsapp-box">
              <a href="https://wa.me/56976433061?text=Hola!%20necesito%20información%20sobre%20sus%20baterías" target="_blank">
                <div className="header-whatsapp-box-title">
                  <Hidden smDown>
                    <h5>Escríbenos por Whatsapp</h5>
                  </Hidden>
                  <Hidden mdUp>
                    <h6>Escríbenos por Whatsapp</h6>
                  </Hidden>
                </div>
                <Grid container alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <WspIcon />
                  </Grid>
                  <Grid item>
                    <h5><span>(+56 9)</span> 7 643 30 61</h5>
                  </Grid>
                </Grid>
              </a>
            </div>
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Header;