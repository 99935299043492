import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Avatar from '@material-ui/core/Avatar';

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
        query {
        usewsp: file(relativePath: { eq: "images/howworks/wsp.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 188, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        batteryhome: file(relativePath: { eq: "images/howworks/battery.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 188, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        installing: file(relativePath: { eq: "images/howworks/install.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 188, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
      `}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const UseWsp = withImageData(props => (
  <Avatar alt="" src={props.imageData.usewsp.childImageSharp.fluid.src} />
));

const BatteryHome = withImageData(props => (
  <Avatar alt="" src={props.imageData.batteryhome.childImageSharp.fluid.src} />
));

const Installing = withImageData(props => (
  <Avatar alt="" src={props.imageData.installing.childImageSharp.fluid.src} />
));

export { UseWsp, BatteryHome, Installing }